<template>
  <div>
    <h1>Add Teacher</h1>
    <a-row>
      <a-col :span="24">
        <div class="card-full">
          <div class="detail">
            <a-form @submit.prevent="add">
              <a-col :span="24">
                <a-form-item label="NIK">
                  <a-input v-model="newData.nik" style="height: 48px" />
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="Nama Lengkap">
                  <a-input v-model="newData.nama" style="height: 48px" />
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="Jenis Kelamin">
                  <a-radio-group v-model="newData.jenis_kelamin">
                    <a-radio value="Laki-Laki">Laki-Laki</a-radio>
                    <a-radio value="Perempuan">Perempuan</a-radio>
                  </a-radio-group>
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="Default Password">
                  <a-input v-model="newData.nik" read-only style="height: 48px" />
                </a-form-item>
              </a-col>
              <a-button
                :loading="loadingAdd"
                type="primary"
                html-type="submit"
                size="large"
                block
              >Add Now</a-button>
            </a-form>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newData: {
        nik: '',
        nama: '',
        jenis_kelamin: '',
      },
      loadingAdd: false,
    }
  },
  methods: {
    add() {
      if (this.newData.nik && this.newData.nama) {
        this.loadingAdd = true
        this.$confirm({
          title: 'Warning',
          content: (
            <div>Are you sure to add this account ?</div>
          ),
          onOk: () => {
            this.loadingAdd = false
            this.$store.dispatch('admin/ADD_TEACHER', { data: this.newData })
              .then(res => {
                if (res === 200) {
                  this.$notification.success({
                    message: 'Success',
                    description:
                      'this account has been added',
                  })
                  this.$router.push({ name: 'Teachers Admin' })
                } else if (res === 'nik must be unique') {
                  this.$notification.error({
                    message: 'Error',
                    description:
                      'this NIK has already taken',
                  })
                } else {
                  this.$notification.error({
                    message: 'Error',
                    description:
                      'this account has not been added',
                  })
                }
              })
          },
          onCancel: () => {
            this.loadingAdd = false
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: 'Add',
        })
      } else {
        this.$notification.error({
          message: 'Error',
          description:
            'You must fill NIK and Nama',
        })
      }
    },
  },
}
</script>

<style scoped>
.card-full {
  padding: 20px 20px;
  margin-top: 60px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #0419301a;
}

.detail {
  padding: 40px 20px 20px 20px;
}
</style>
